<template>
  <div class="appContainer spaceBox flex-between">
    <div class="leftBox bb">
      <div class="flex-end header">
        <el-tooltip effect="dark" content="新增" placement="top-start">
          <el-button
            icon="el-icon-plus"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="addFn"
          />
        </el-tooltip>
        <el-tooltip effect="dark" content="刷新列表" placement="top-start">
          <el-button
            icon="el-icon-refresh"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="refreshListFn"
          />
        </el-tooltip>
      </div>
      <div class="list" v-if="spaceList.length" v-loading="loadingList">
        <span
          v-for="(item, key) in spaceList"
          class="pointer  text flex-between"
          :key="key"
          :class="{ active: item.spaceId == form.spaceId }"
          @click.stop="selectSpaceFn(item)"
        >
          <span>{{ item.name }}</span>
          <el-popconfirm
            title="确定删除该空间吗？"
            @confirm="deleSpaceFn(item)"
          >
            <el-button
              type="danger"
              size="mini"
              class="deleBtn "
              slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
        </span>
      </div>
      <p v-else class="empty bb text-center">暂无数据</p>
    </div>
    <div class="rightBox bb" v-loading="loadingDetial">
      <template v-if="form.spaceId">
        <el-card class="box-card" v-for="item in cardList" :key="item.id">
          <div slot="header">
            <span class="fW600">{{ item.name }}</span>
          </div>
          <template v-if="item.id == 'base'">
            <div class="line-box">
              <b>空间名称：</b>
              <span class=" pointer text" @dblclick="dbItemFn()">
                <el-tooltip
                  effect="dark"
                  content="双击编辑名称"
                  placement="top-start"
                >
                  <label v-show="!isEditName">{{ form.name }}</label>
                </el-tooltip>
                <editInput
                  ref="editInputChild"
                  v-show="isEditName"
                  :name="form.name"
                  :width="'300px'"
                  :placeholder="'请输入空间名称'"
                  :word_limit_length="64"
                  :inputType="'text'"
                  @inputBlur="inputBlurFn"
                />
              </span>
            </div>
          </template>
          <template v-if="item.id == 'config'">
            <div class="line-box">
              <b>自我管理框架：</b
              ><span class="text">
                <el-radio-group
                  v-model="spaceIsFrame"
                  @change="changeRadioFn('spaceIsFrame')"
                >
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </span>
            </div>
            <div class="line-box">
              <b>自我管理授权：</b
              ><span class="text">
                <el-radio-group
                  v-model="spaceIsAuthor"
                  @change="changeRadioFn('spaceIsAuthor')"
                >
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </span>
            </div>
            <div class="flex-start line-box" style="margin:25px 0 0 40px">
              <el-button
                type="primary"
                @click="configBtnsFn('menu')"
                size="small"
                >配置菜单</el-button
              >
              <el-button
                type="success"
                @click="configBtnsFn('role')"
                size="small"
                >配置角色</el-button
              >
              <el-button
                type="warning"
                @click="configBtnsFn('user')"
                size="small"
                >配置用户</el-button
              >

              <el-button type="info" @click="configBtnsFn('apply')" size="small"
                >配置应用</el-button
              >
              <el-button
                type="danger"
                @click="configBtnsFn('source')"
                size="small"
                >配置资源</el-button
              >
            </div>
          </template>
        </el-card>
      </template>
      <el-empty
        v-if="!form.spaceId"
        style="margin-top:200px"
        description="暂无数据，请点击左侧列表"
      />
    </div>
    <addDialog
      :showDialog="showAddDialog"
      @closeDialog="addCloseDialogFn"
      @sureDialog="addSureDialogFn"
    />
    <confirmDialog
      :showConfirmDialog="showConfirmDialog"
      @closeConfirm="closeConfirmFn"
      @sureConfirm="sureConfirmFn"
    >
      <span v-if="changeRadioType != 'spaceStatus'"
        >确认<label class="red">{{ confirmAction.name }} </label>配置为<label
          class="red"
          >{{ confirmAction.action }}</label
        >吗？</span
      >
      <span v-else
        >确认<label class="red">{{ confirmAction.action }} </label
        >该空间吗？</span
      >
    </confirmDialog>
    <!-- 应用配置 -->
    <configApply
      :show="showConfigApp"
      :spaceId="form.spaceId"
      @close="closeConfigApplyFn"
    />
    <configSources
      :show="showConfigSource"
      :spaceId="form.spaceId"
      @close="showConfigSource = false"
    />
  </div>
</template>

<script>
import {
  spaces,
  // A_space,
  add_edit_Space,
  remove_space,
  space_ifrepeat,
} from '@/api/space'
export default {
  name: 'Space',
  components: {
    addDialog: () => import('./components/add.vue'),
    confirmDialog: () => import('@/views/components/confirmDialog.vue'),
    editInput: () => import('@/views/components/editInput'),
    configApply: () => import('./components/config_apply.vue'),
    configSources: () => import('./components/config_source_table.vue'),
  },
  data() {
    return {
      loadingList: false,
      loadingDetial: false,
      spaceList: [],
      currentId: null,
      spaceDetial: {},
      form: {
        isFrameSelfManaged: 0,
        isAuthorizationSelfManaged: 0,
        spaceId: null,
        name: '',
      },
      spaceIsFrame: '0',
      spaceIsAuthor: '0',
      spaceStatus: '0',
      showAddDialog: false,
      confirmAction: {},
      showDesInput: false,
      showConfirmDialog: false,
      changeRadioType: '',
      cardList: [
        { name: '基本信息', id: 'base' },
        { name: '配置空间', id: 'config' },
      ],
      isEditName: false,
      showConfigApp: false,
      showConfigSource: false,
    }
  },
  created() {
    this.getSpaces()
  },
  methods: {
    deleSpaceFn(row) {
      remove_space({
        spaceId: row.spaceId.toString(),
      }).then((res) => {
        this.$message.success('删除成功')
        this.form.spaceId = null
        this.spaceDetial = null
        this.getSpaces()
      })
    },
    closeConfigApplyFn() {
      this.showConfigApp = false
    },
    inputBlurFn(v) {
      if (v == '') {
        this.$message.warning('空间名称不能为空')
        return
      }
      this.form.name = v
      this.add_edit_Space({ ...this.spaceDetial, ...this.form }, () => {
        this.isEditName = false
        this.$message.success('保存成功')
        this.getSpaces()
      })
    },
    dbItemFn() {
      this.isEditName = true
      this.$nextTick((_) => {
        this.$refs.editInputChild[0].$refs.nameInput.$refs.input.focus()
      })
    },
    configBtnsFn(type) {
      // console.log(this.form.spaceId, 'this.form.spaceId')
      if (type == 'menu') {
        this.$router.push({
          path: `/spaceMenus/${this.form.spaceId}/${encodeURIComponent(
            this.form.name
          )}`,
        })
      } else if (type == 'role') {
        this.$router.push({
          path: `/spaceRoles/${this.form.spaceId}/${encodeURIComponent(
            this.form.name
          )}`,
        })
      } else if (type == 'user') {
        this.$router.push({
          path: `/spaceUsers/${this.form.spaceId}/${encodeURIComponent(
            this.form.name
          )}`,
        })
      } else if (type == 'apply') {
        this.showConfigApp = true
      } else if (type == 'source') {
        // this.$message.info('待开发')
        this.showConfigSource = true
      }
    },
    closeConfirmFn() {
      this.showConfirmDialog = false
      let a
      let type = this.changeRadioType
      if (this.changeRadioType == 'spaceIsFrame') {
        a = this.spaceIsFrame == '1' ? '0' : '1'
      } else if (this.changeRadioType == 'spaceIsAuthor') {
        a = this.spaceIsAuthor == '1' ? '0' : '1'
      } else if (this.changeRadioType == 'spaceStatus') {
        a = this.spaceStatus == '1' ? '0' : '1'
      }
      this.$set(this, type, a)
    },
    sureConfirmFn() {
      this.form.isFrameSelfManaged = Number(this.spaceIsFrame)
      this.form.isAuthorizationSelfManaged = Number(this.spaceIsAuthor)
      // console.log({ ...this.spaceDetial, ...this.form })
      this.add_edit_Space({ ...this.spaceDetial, ...this.form }, () => {
        this.$message.success('保存成功')
        this.showConfirmDialog = false
        this.getSpaces()
      })
    },

    addSureDialogFn(v) {
      // space_ifrepeat({
      //   id: '',
      //   name: 'name',
      //   value: v.name,
      // }).then((res) => {
      //   console.log(res)
      //   if (!res) {
      //     this.add_edit_Space(v, () => {
      //       this.$message.success('新增成功')
      //       this.showAddDialog = false
      //       this.getSpaces()
      //     })
      //   } else {
      //     this.$message.error('空间名称已存在')
      //   }
      // })
      this.add_edit_Space(v, () => {
        this.$message.success('新增成功')
        this.showAddDialog = false
        this.getSpaces()
      })
    },
    add_edit_Space(params, cb) {
      space_ifrepeat({
        id: this.isEditName ? this.form.spaceId : '',
        name: 'name',
        value: params.name,
      }).then((res) => {
        if (!res) {
          add_edit_Space(params).then((callback) => {
            cb && cb()
          })
        } else {
          this.$message.warning('空间名已存在')
        }
      })
    },
    addCloseDialogFn() {
      this.showAddDialog = false
    },
    changeRadioFn(type) {
      this.changeRadioType = type
      if (type == 'spaceIsFrame') {
        this.confirmAction.name = '自我管理框架'
        this.confirmAction.action = this.spaceIsFrame == '1' ? '是' : '否'
        this.showConfirmDialog = true
      } else if (type == 'spaceIsAuthor') {
        this.confirmAction.name = '自我管理授权'
        this.confirmAction.action = this.spaceIsAuthor == '1' ? '是' : '否'
        this.showConfirmDialog = true
      } else if (type == 'spaceStatus') {
        this.showConfirmDialog = true
        this.confirmAction.name = ''
        this.confirmAction.action = this.spaceStatus == '1' ? '展示' : '不展示'
      }
    },
    refreshListFn() {
      let timer = setTimeout(() => {
        this.getSpaces(() => {
          this.$message.success('刷新成功')
        })
        clearTimeout(timer)
      }, 200)
    },

    // async get_A_space() {
    //   this.loadingDetial = true
    //   const res = await A_space({ spaceId: this.form.spaceId })
    //   try {
    //     this.spaceDetial = res
    //     this.spaceIsFrame = this.spaceDetial.isFrameSelfManaged + '' //是否自我管理授权 0:否 1:是
    //     this.spaceIsAuthor = this.spaceDetial.isAuthorizationSelfManaged + '' //是否自我管理框架 0:否 1:是
    //     this.form.name = this.spaceDetial.name
    //     this.loadingDetial = false
    //   } catch {
    //     this.loadingDetial = false
    //   }
    // },
    async getSpaces(cb) {
      this.loadingList = true
      const res = await spaces({ sort: '' })
      try {
        this.spaceList = res
        this.loadingList = false
        cb && cb()
      } catch {
        this.loadingList = false
      }
    },
    addFn() {
      this.showAddDialog = true
    },
    selectSpaceFn(v) {
      this.spaceDetial = v
      this.form.spaceId = v.spaceId.toString()
      this.isEditName = false
      this.spaceIsFrame = this.spaceDetial.isFrameSelfManaged + '' //是否自我管理授权 0:否 1:是
      this.spaceIsAuthor = this.spaceDetial.isAuthorizationSelfManaged + '' //是否自我管理框架 0:否 1:是
      this.form.name = this.spaceDetial.name
      // this.get_A_space()
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  height: 40px;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.list {
  margin-top: 8px;
  height: calc(100% - 60px);
  overflow: auto;

  .text {
    padding: 5px 10px;
    line-height: 20px;
    margin-bottom: 8px;
    .deleBtn {
      display: none;
      padding: 2px;
    }
    &:hover {
      background: #e8f1ff;
      .deleBtn {
        display: inline-block;
      }
    }
  }
  .active {
    background: #e8f1ff;
    .deleBtn {
      display: inline-block;
    }
  }
}

.box-card {
  margin-top: 10px;
  .line-box {
    // text-align: center;
    line-height: 32px;
    margin-bottom: 10px;
    b {
      min-width: 140px;
      text-align: right;
      display: inline-block;
      margin-right: 5%;
    }
    .text {
      min-width: 200px;
      text-align: left;
      display: inline-block;
    }
  }
}
</style>
