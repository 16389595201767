import request from "@/utils/request"

// 空间管理
export function spaces (params) {
    return request({
        url: "/manage/space/find/all",
        method: "get",
        params
    })
}
//获取后台门户空间 v1
export function A_space (data) {
    return request({
        url: `/manage/space/${data.spaceId}`,
        method: "get",
        // data
    })
}
//新增,编辑后台门户空间 v1
export function add_edit_Space (data) {
    return request({
        url: "/manage/space",
        method: "post",
        data
    })
}
//保存.设置的空间应用
export function save_config_apply (data) {
  return request({
      url: `/manage/space/${data.spaceId}/app`,
      method: "post",
      data
  })
}
//删除.设置的空间应用
export function remove_config_apply (data) {
  return request({
      url: `/manage/space/app/${data.spaceAppId}`,
    method: "delete",
      // data
  })
}
//删除.空间
export function remove_space (data) {
  return request({
      url: `/manage/space/${data.spaceId}`,
    method: "delete",
      // data
  })
}
//验证重复, true: 已存在重复的字段, false: 不存在
export function space_ifrepeat (params) {
  return request({
      url: `/manage/space/duplicate`,
      method: "get",
      params
  })
}
//空间应用 验证重复, true: 已存在重复的字段, false: 不存在
export function space_app_ifrepeat (params) {
  return request({
      url: `/manage/space/app/duplicate`,
      method: "get",
      params
  })
}
//创建.空间资源
export function add_space_source (data) {
  return request({
    url: `/manage/space/permission/${data.spaceId}`,
    method: "post",
    data
})
}
//查询.空间资源
export function get_space_source (data) {
return request({
  url: `/manage/space/permission/${data.spaceId}`,
  method: "get",
  // data
})
}
//删除.空间资源
export function delete_space_source (data) {
  return request({
    url: `/manage/space/permission/${data.tenantPermissionId}`,
    method: "delete",
    // params
})
}
//查询.空间资源.匹配
export function search_space_math (params) {
  return request({
    url: `/manage/space/permission/match/${params.tenantPermissionId}`,
    method: "get",
    // params
})
}
//创建/编辑.空间资源.匹配
export function add_edit_spaceSource_match (data) {
  return request({
    url: `/manage/space/permission/match/${data.tenantPermissionId}`,
    method: "post",
    data
})
}
//删除.空间资源.匹配
export function remove_spaceSource_match (data) {
  return request({
    url: `/manage/space/permission/match/${data.tenantPermissionResourceMatchId}`,
    method: "delete",
    // data
})
}
